import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Today I had to work on project that was 1 year old and a lot of the dependencies where outdated.  `}</p>
    <p>{`We can run the command `}<inlineCode parentName="p">{`npm outdated`}</inlineCode>{` to get a list of all the dependencies that have new releases.`}</p>
    <p><img parentName="p" {...{
        "src": "../../images/outdated.png",
        "alt": "outdated list"
      }}></img></p>
    <p>{`We then need a way to update them all, that’s when another package called `}<inlineCode parentName="p">{`npm-check-updates`}</inlineCode>{` comes in handy. This package will upgrade the package.json versions of all outdated dependences.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install -g npm-check-updates
`}</code></pre>
    <p><em parentName="p">{`Note: You should install this package globally so you can use on other projects.`}</em></p>
    <p>{`After installing the package you need to run `}<inlineCode parentName="p">{`ncu -u`}</inlineCode>{`  on your terminal to upgrade  your package.json.`}</p>
    <p><img parentName="p" {...{
        "src": "../../images/updated-list.png",
        "alt": "updated list"
      }}></img></p>
    <p>{`Finally you can run the command `}<inlineCode parentName="p">{`npm update`}</inlineCode>{` to update your dependencies.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      